import React from 'react'
import { Link } from 'gatsby'

import './index.scss'

const Header = ({ menuItems, isOpen, toggleMenu, closeMenu }) => (
  <div className='b-header'>
    <div className='b-header-wrap'>
      <div className='b-header-main'>
        <div className='b-header-logo'>
          <Link to='/' className='b-main-logo'>
            <span>Logo</span>
          </Link>
        </div>
        <nav className={`b-header-nav ${!isOpen || 'm-open'}`}>
          <ul className='b-main-menu'>
            {menuItems.map(({ name, url, active }, key) =>
              <li key={key} className={`b-main-menu-item ${!active || 'm-current'}`}>
                <Link to={url} onClick={closeMenu}>{name}</Link>
              </li>
            )}
          </ul>
        </nav>
        <button type='button' className={`b-nav-burger-main ${!isOpen || 'm-active'}`} onClick={toggleMenu}>
          <span className='b-nav-burger' />
        </button>
      </div>
    </div>
  </div>
)

export default Header
