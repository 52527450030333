import React from 'react'
import { Link } from 'gatsby'

import './list.scss'

const ConstructionList = ({ children, title, cta: { link, title: ctaTitle } }) => (
  <div className='b-works-main'>
    <div className='l-main'>
      <div className='b-works-main-title'>
        <h2 className='b-main-title m-2'>{title}</h2>
      </div>
      <div className='b-works'>
        {children}
      </div>
    </div>
    <div className='b-works-main-button'>
      <Link to={link} className='b-main-button'>{ctaTitle}</Link>
    </div>
  </div>
)

export default ConstructionList
