const getMetaTags = ({ description, tags }) => {
  const metaTags = [
    { name: 'description', content: description },
  ]

  if (tags) metaTags.push({ name: 'article:tag', content: tags })

  return metaTags
}

export default getMetaTags
