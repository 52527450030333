import React from 'react'

import './item.scss'

const ThatGallery = ({ key, src }) => {
  return (
    <div key={key} className='b-works-item-gallery-item'>
      <img src={src} />
    </div>
  )
}

const ConstructionItem = ({ project: { title, description: __html, gallery } }) => {
  return (
    <div className='b-works-item js-works-item'>
      <div className='b-works-item-main'>
        <div className='b-works-item-main-content js-works-content'>
          <div className='b-works-item-main-title'>
            <h3 className='b-main-title m-3'>{title}</h3>
          </div>
          <div className='b-works-item-main-description' dangerouslySetInnerHTML={{ __html }} />
        </div>
      </div>
      <div className='b-works-item-gallery'>
        {
          (!gallery || gallery.length === 0) || gallery.map(({ name }, key) => <ThatGallery key={key} src={!name || name.childImageSharp.fixed.src} />)
        }
      </div>
    </div>
  )
}

export default ConstructionItem
