import React from 'react'
import './index.scss'

const Footer = ({ company: { name, address, phone, email, facebook } }) => {
  const { street, municipality, county } = address

  const contactItemMapper = ({ key, value }, index) => (
    <div key={index} className='b-footer-contact-item'>
      <a href={`${key}:${value.replace(/ /g, '')}`}>{value}</a>
    </div>
  )

  const ContactItems = [
    { key: 'tel', value: phone },
    { key: 'mailto', value: email },
  ].map(contactItemMapper)

  return (
    <div className='b-footer'>
      <div className='b-footer-main'>
        <div className='b-footer-info'>
          <div className='b-footer-company'>
            <p className='b-footer-company-name'>{name}</p>
            <div className='b-footer-company-address'>
              <p>{street}</p>
              <p>{municipality}</p>
              <p>{county}</p>
            </div>
          </div>
          <div className='b-footer-contact'>
            {ContactItems}
          </div>
        </div>
        <div className='b-footer-social'>
          <a href={facebook} target='_blank' rel='noreferrer' className='b-footer-social-item m-facebook'>Facebook</a>
          <a href={`mailto:${email}`} className='b-footer-social-item m-email'>Email</a>
        </div>
      </div>
    </div>
  )
}

export default Footer
