import React from 'react'

import './intro.scss'

const PageIntro = ({ html: __html }) => (
  <div className='l-main'>
    <div className='b-page-intro' dangerouslySetInnerHTML={{ __html }} />
  </div>
)

export default PageIntro
