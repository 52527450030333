import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import getMetaTags from '../utils/seo'

import Layout from '../components/layout'
import PageTitle from '../components/page/title'
import PageIntro from '../components/page/intro'
import ConstructionList from '../components/construction/list'
import ConstructionItem from '../components/construction/item'

export const ConstructionPageTemplate = ({ title, intro, projects }) => (
  <div>
    <PageTitle title={title} />
    <PageIntro html={intro} />
    <ConstructionList title='Tehtud tööd' cta={{ link: '/kontakt', title: 'Küsi pakkumist' }}>
      {projects.map((project, index) =>
        <ConstructionItem key={index} project={project} />
      )}
    </ConstructionList>
  </div>
)

const ConstructionPage = ({ data: { markdownRemark, allMarkdownRemark } }) => {
  const { frontmatter: { title, meta }, html: intro } = markdownRemark

  const projects = allMarkdownRemark ? allMarkdownRemark.edges
    .map(({ node: { frontmatter, html } }) => ({ ...frontmatter, description: html })) : []

  return (
    <Layout>
      <Helmet title={'Ehitus'} meta={getMetaTags(meta)} />
      <ConstructionPageTemplate title={title} intro={intro} projects={projects} />
    </Layout>
  )
}

export default ConstructionPage

export const constructionPageQuery = graphql`
  query ConstructionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        meta {
          description
          tags
        }
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___created] },
      filter: { frontmatter: { templateKey: { eq: "_ConstructionDetailTemplate" } }}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            gallery {
              name {
                childImageSharp {
                  fixed(width: 1500, height: 840) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
