import React from 'react'

import './title.scss'

const PageTitle = ({ title }) => (
  <div className='b-page-title'>
    <div className='b-page-title-main'>
      <h1 className='b-main-title m-2'>{title}</h1>
    </div>
  </div>
)

export default PageTitle
