import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Location } from '@reach/router'

import favicon from './favicon.png'
import Header from './header'
import Footer from './footer'

import './styles.scss'

const query = graphql`
  query IndexLayoutQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sort] }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "ContactTemplate" } }) {
      frontmatter {
        company {
          name
          phone
          email
          facebook
          address {
            street
            municipality
            county
          }
        }
      }
    }
  }
`

const isActive = (url, pathname) => url === '/' ? url === pathname : ~pathname.indexOf(url)

class LayoutTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  toggleMenu () {
    this.setState({ open: !this.state.open })
  }

  closeMenu () {
    this.setState({ open: false })
  }

  render () {
    const { data, children, location: { pathname } } = this.props
    const { allMarkdownRemark: { edges }, markdownRemark: { frontmatter: { company } } } = data

    const menuItems = edges
      .filter(({ node: { frontmatter: { path } } }) =>
        path !== null
      )
      .map(({ node: { frontmatter: { title: name, path: url } } }) =>
        ({ name, url, active: isActive(url, pathname) })
      )

    const { open } = this.state

    return (
      <div className={`l-master ${!open || 'm-fixed'}`}>
        <Header menuItems={menuItems} isOpen={open} toggleMenu={this.toggleMenu} closeMenu={this.closeMenu} />
        {children}
        <Footer company={company} />
      </div>
    )
  }
}

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <div>
        <Helmet htmlAttributes={{ lang: 'et' }} link={[{ rel: 'icon', type: 'image/png', href: favicon }]} />
        <Location>
          {({ _, location }) => <LayoutTemplate data={data} children={children} location={location} />}
        </Location>
      </div>
    )}
  />
)
